import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import styles from './SettingPage.module.scss';

export default function SettingPage() {
  return (
    <section className={styles.setting}>
      <div className="container">
        <Outlet />
      </div>
    </section>
  );
}
