import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { useFetchMyProfile } from 'hook/query/useFetchMyProfile';
import WaveSurfer from 'wavesurfer.js';

import Button from 'components/UI/Button';
import { useSocket } from 'providers/SocketProvider';
import { IMessage } from 'types/Chat.type';
import { EventsSocketEnum } from 'types/interfaces/eventsSockets.enum';
import { formatDateToTime } from 'utils/formatDate';
import ScrollIcon from 'assets/icons/ScrollIcon';
import useFetchMyChat from '../../hook/query/useFetchMyChat';
import AudioElem from './components/AudioElem';
import ChatForm from './components/ChatForm';

import styles from './ChatPage.module.scss';

export default function ChatPage() {
  const { socket } = useSocket();
  const { user } = useFetchMyProfile();
  const { data: chat } = useFetchMyChat(user?.id!);

  const [messages, setMessages] = useState<IMessage[]>(chat || []);

  const messageEndRef = useRef<HTMLDivElement>(null);
  const [isArrowActive, setIsArrowActive] = useState(false);

  useEffect(() => {
    if (chat) {
      setMessages(chat);
    }
  }, [chat]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
      setIsArrowActive(false);
      setTimeout(() => {
        if (messageEndRef.current) {
          messageEndRef.current.style.scrollBehavior = 'smooth';
        }
      }, 1000);
    }
  }, [messages]);

  useEffect(() => {
    if (!socket) return;

    const handleMessageReceived = (message: IMessage) => {
      console.log(message);
      setMessages((prevMessages) => {
        const isMessageExists = prevMessages.some(
          (msg) => msg.id === message.id,
        );
        if (isMessageExists) {
          return prevMessages;
        }
        return [...prevMessages, message];
      });
    };

    socket.on(EventsSocketEnum.MESSAGE_SENT_EVENT, handleMessageReceived);

    return () => {
      socket.off(EventsSocketEnum.MESSAGE_SENT_EVENT, handleMessageReceived);
    };
  }, [socket]);

  useEffect(() => {
    const handleResize = () => {
      if (messageEndRef.current) {
        const scrollTop = messageEndRef.current.scrollTop;
        const scrollHeight = messageEndRef.current.scrollHeight;
        const offsetHeight = messageEndRef.current.offsetHeight;

        setIsArrowActive(scrollTop < scrollHeight - offsetHeight - 100);
      }
    };

    window.addEventListener('wheel', handleResize);
    return () => {
      window.removeEventListener('wheel', handleResize);
    };
  }, []);

  const handleScroll = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
      setIsArrowActive(false);
    }
  };

  return (
    <section className={styles.chats}>
      <div className="container">
        <div className={styles.wrapper} ref={messageEndRef}>
          <div className={styles.messages}>
            {messages.map((message) => (
              <div
                className={clsx(
                  styles.message,
                  message.sender.id === user?.id && styles.isSender,
                )}
                key={message.id}
              >
                {message.audio ? (
                  <AudioElem
                    url={`${process.env.REACT_APP_API_ENDPOINT}/files/audio/${message.audio}`}
                  />
                ) : (
                  <>
                    <div className={styles.message__text}>{message.text}</div>
                    <div className={styles.message__time}>
                      {formatDateToTime()}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <Button
            className={clsx(
              styles.scrollButton,
              isArrowActive && styles.scrollActive,
            )}
            onClick={handleScroll}
          >
            <ScrollIcon />
          </Button>
        </div>
        <ChatForm user={user} />
      </div>
    </section>
  );
}
