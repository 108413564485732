import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hook/store';

import { setTheme } from 'store/slices/themeSlice';

export default function PasteTheme() {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.theme);

  useEffect(() => {
    if (theme === 'dark') {
      dispatch(setTheme('dark'));
    } else if (theme === 'custom') {
      dispatch(setTheme('custom'));
    } else {
      dispatch(setTheme('light'));
    }
  }, []);

  return null;
}
