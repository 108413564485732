import SignupForm from 'components/SignupForm';
import Button from 'components/UI/Button';

import styles from './SignUpPage.module.scss';

interface SignUpPageProps {}

export default function SignUpPage({}: SignUpPageProps) {
  return (
    <div className={styles.signUp}>
      <div className={styles.container}>
        <h2 className={styles.signUp__title}>Signup</h2>
        <SignupForm />
      </div>
    </div>
  );
}
