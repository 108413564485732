import { Link } from 'react-router-dom';
import { useFetchBlogger } from 'hook/query/useFetchBlogger';

import avatarImage from 'assets/images/avatar.png';
import { useFetchMyProfile } from '../../../hook/query/useFetchMyProfile';

import styles from './Header.module.scss';

export default function Header() {
  const { user } = useFetchMyProfile();
  const { blogger } = useFetchBlogger();

  return (
    <header className={styles.header}>
      <Link to={'/'} className={styles.header__left}>
        <img className={styles.header__avatar} src={avatarImage} alt="avatar" />
        <h3 className={styles.header__title}>{blogger?.name}</h3>
          {user?.referralCode}
      </Link>
      <Link to="/settings" className={styles.header__button}>
        {user?.email}
      </Link>
    </header>
  );
}
