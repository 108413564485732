import { useNavigate } from 'react-router-dom';
import { useFetchMyProfile } from 'hook/query/useFetchMyProfile';
import { useAppDispatch } from 'hook/store';

import Button from 'components/UI/Button';
import { removeToken } from 'store/slices/authSlice';
import { RoleEnum } from 'types/interfaces/role.enum';
import { checkRole } from 'utils/checkRole';
import { AccountList } from 'constants/setting';
import SettingItem from '../SettingItem';

import styles from './MainSettings.module.scss';

export default function MainSettings() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ACCOUNTLIST, GENERALLIST } = AccountList();
  const { user } = useFetchMyProfile();

  return (
    <div className={styles.settings}>
      <div className={styles.left}>
        {user?.role === RoleEnum.USER && (
          <div className={styles.subscribe}>
            <h3 className={styles.subscribe__title}>
              Managing a paid subscription
            </h3>
            <div className={styles.subscribe__right}>
              <div className={styles.subscribe__right_expire}>
                Valid until 05/01/2024
              </div>
              <Button className={styles.subscribe__right_button}>
                Cancel subscription
              </Button>
            </div>
          </div>
        )}
        <SettingItem
          title="Account"
          list={ACCOUNTLIST}
          handleLogout={() => {
            dispatch(removeToken());
            navigate('/');
          }}
        />
        <SettingItem title="General" list={GENERALLIST} />
      </div>
      {checkRole(user?.role!, RoleEnum.BLOGGER) && (
        <div className={styles.right}>
          <div className={styles.info}>
            <h3 className={styles.info__title}>Balance:</h3>
            <div className={styles.info__content}>
              <h4 className={styles.info__value}>
                10000 <span>₽</span>
              </h4>
              <Button variants="filled" className={styles.info__button}>
                Вывести
              </Button>
            </div>
          </div>
          <div className={styles.info}>
            <h3 className={styles.info__title}>Subscribers:</h3>
            <div className={styles.info__content}>
              <h4 className={styles.info__value}>312</h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
