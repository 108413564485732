import LoginForm from 'components/LoginForm';
import Button from 'components/UI/Button';

import styles from './LoginPage.module.scss';

export default function LoginPage() {
  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <h2 className={styles.login__title}>Login</h2>
        <LoginForm />
      </div>
    </div>
  );
}
