import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'hook/store';

import Layout from 'components/layout/Layout';
import EditSubscription from 'components/settings/EditSubscription';
import MainSettings from 'components/settings/MainSettings';
import ReceiveOnly from 'components/settings/ReceiveOnly';
import ThemeList from 'components/settings/ThemeList';
import ChatPage from 'pages/ChatPage';
import LoginPage from 'pages/LoginPage';
import SettingPage from 'pages/SettingPage';
import SignUpPage from 'pages/SignUpPage';
import SubscribePage from 'pages/SubscribePage';

// import { useFetchMyProfile } from './hook/query/useFetchMyProfile';

export default function Router() {
  const isAuth = useAppSelector((state) => state.auth.isAuthInitialized);

  // const profileData = useFetchMyProfile();

  return (
    <Routes>
      {!isAuth ? (
        <>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signup/:code" element={<SignUpPage />} />
        </>
      ) : (
        <Route path="/" element={<Layout />}>
          {/* profileData?.user?.isSubscribed Поменять */}
          {true ? (
            <Route index element={<ChatPage />} />
          ) : (
            <Route index element={<SubscribePage />} />
          )}
          <Route path="settings" element={<SettingPage />}>
            <Route index element={<MainSettings />} />
            <Route path="theme" element={<ThemeList />} />
            <Route path="receive-only" element={<ReceiveOnly />} />
            <Route path="edit-subscription" element={<EditSubscription />} />
          </Route>
        </Route>
      )}
    </Routes>
  );
}
